import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/logo/accessibility",
  "title": "Logo - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`A text alternative must always be provided for logos using the WAI-ARIA definition `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{`. See `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#text-alternatives" mdxType="ExternalLink">{`WCAG 2.1 Text Alternatives guideline`}</ExternalLink>{` for more information.`}
        <ul parentName="li">
          <li parentName="ul">{`When using the logo alone without a service name, provide a localised `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` `}<em parentName="li">{`"City of Helsinki"`}</em>{`.`}</li>
          <li parentName="ul">{`When using the logo with the service name, provide a localised `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` `}<em parentName="li">{`"City of Helsinki: `}{`[Name of the service]`}{`"`}</em>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The colour contrast between the logo and its background must comply with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#contrast-minimum" mdxType="ExternalLink">{`AA Level contrast ratios`}</ExternalLink>{`. You can find more information about accessible colour combinations in `}<InternalLink href="/foundation/design-tokens/colour" mdxType="InternalLink">{`Colour design token documentation`}</InternalLink>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      